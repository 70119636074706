import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import SimulationType from './lib/SimulationType';
import WefSimulation from './templates/organisms/wef-simulation';
import PensionCertificateSimulation from './templates/organisms/pension-certificate-simulation';
import PensionCalculatorSimulation from './templates/organisms/pension-calculator-simulation';
import PurchaseSimulation from './templates/organisms/purchase-simulation';
import PurchaseSimulationAdditionalAccount from './templates/organisms/purchase-simulation-additional-account';
import SavingPlanSimulation from "./templates/organisms/saving-plan-simulation";

loadWidget(`.plf-simulation[data-area="${SimulationType.Wef}"]`, simulationWidget => {
    const action = simulationWidget.dataset.action;
    const maxWefAction = simulationWidget.dataset.maxWefAction;
    const headerIdSimulation = simulationWidget.dataset.headerIdSimulation;
    const requestAction = simulationWidget.dataset.wefRequestAction;
    const linkToRegulationsDocument = simulationWidget.dataset.linkToRegulationsDocument;
    ReactDOM.render(<WefSimulation action={action} headerIdSimulation={headerIdSimulation} maxWefAction={maxWefAction} requestAction={requestAction} linkToRegulationsDocument={linkToRegulationsDocument} />, simulationWidget);
});

loadWidget(`.plf-simulation[data-area="${SimulationType.PensionCertificate}"]`, simulationWidget => {
    const calculatingAction = simulationWidget.dataset.action;
    const headerIdSimulation = simulationWidget.dataset.headerIdSimulation;
    ReactDOM.render(<PensionCertificateSimulation calculatingAction={calculatingAction} headerIdSimulation={headerIdSimulation} />, simulationWidget);
});

loadWidget(`.plf-simulation[data-area="${SimulationType.PensionCalculator}"]`, simulationWidget => {
    const calculatingAction = simulationWidget.dataset.pensionCalculatorAction;
    const headerIdSimulation = simulationWidget.dataset.headerIdSimulation;
    const dataTableAction = simulationWidget.dataset.dataTablePensionCalculator;
    const ordinaryRetirementDateAction = simulationWidget.dataset.ordinaryRetirementDateAction;
    const birthdateAction = simulationWidget.dataset.birthdateAction;
    const retirementPercentageAction = simulationWidget.dataset.retirementPercentageAction;
    const infosOnRetirementDateAction = simulationWidget.dataset.infosOnRetirementDateAction;
    const generalInfosAction = simulationWidget.dataset.generalInfosAction;
    const linkToCapitalPayments = simulationWidget.dataset.linkToCapitalPayments;
    const shouldDisplayDataTable = Boolean(simulationWidget.dataset.shouldDisplayDataTable);
    ReactDOM.render(<PensionCalculatorSimulation action={calculatingAction} dataTableAction={dataTableAction} getOrdinaryRetirementDateAction={ordinaryRetirementDateAction} getBirthdateAction={birthdateAction} getRetirementPercentageAction={retirementPercentageAction} getInfosOnRetirementDateAction={infosOnRetirementDateAction} getGeneralInfosAction={generalInfosAction} headerIdSimulation={headerIdSimulation} linkToCapitalPayments={linkToCapitalPayments} shouldDisplayDataTable={shouldDisplayDataTable} />, simulationWidget);
});

loadWidget(`.plf-simulation[data-area="${SimulationType.Purchase}"]`, simulationWidget => {
    const calculatingAction = simulationWidget.dataset.purchaseAction;
    const headerIdSimulation = simulationWidget.dataset.headerIdSimulation;
    const maxPurchaseAction = simulationWidget.dataset.maxPurchaseAction;
    const linkToMessageTool = simulationWidget.dataset.linkToMessageTool;
    const linkToRegulationsDocument = simulationWidget.dataset.linkToRegulationsDocument;
    const requestAction = simulationWidget.dataset.requestAction;
    ReactDOM.render(<PurchaseSimulation action={calculatingAction} headerIdSimulation={headerIdSimulation} linkToMessageTool={linkToMessageTool} linkToRegulationsDocument={linkToRegulationsDocument} maxPurchaseAction={maxPurchaseAction} requestAction={requestAction} />, simulationWidget);
});

loadWidget(`.plf-simulation[data-area="${SimulationType.PurchaseAdditionalAccount}"]`, simulationWidget => {
    const action = simulationWidget.dataset.purchaseAction;
    const getBirthdateAction = simulationWidget.dataset.birthdateAction;
    const headerIdSimulation = simulationWidget.dataset.headerIdSimulation;
    const linkToMessageTool = simulationWidget.dataset.linkToMessageTool;
    const linkToRegulationsDocument = simulationWidget.dataset.linkToRegulationsDocument;
    const maxPurchaseAdditionalAccountAction = simulationWidget.dataset.maxPurchaseAdditionalAccountAction;
    ReactDOM.render(<PurchaseSimulationAdditionalAccount action={action} headerIdSimulation={headerIdSimulation} linkToMessageTool={linkToMessageTool} linkToRegulationsDocument={linkToRegulationsDocument} maxPurchaseAdditionalAccountAction={maxPurchaseAdditionalAccountAction} getBirthdateAction={getBirthdateAction} />, simulationWidget);
});

loadWidget(`.plf-simulation[data-area="${SimulationType.SavingPlan}"]`, simulationWidget => {
    const headerIdSimulation = simulationWidget.dataset.headerIdSimulation;
    const infoTableAction = simulationWidget.dataset.infoTableAction;
    const savingPlanSimulationDataAction = simulationWidget.dataset.savingPlanSimulationDataAction;
    const savingPlanChangeDataAction = simulationWidget.dataset.savingPlanChangeDataAction;
    const savingPlanChangeSendAction = simulationWidget.dataset.savingPlanChangeSendAction;
    const nextChangeDateAction = simulationWidget.dataset.savingPlanNextChangeDateAction;
    const generalInfoAction = simulationWidget.dataset.savingPlanGeneralInfoAction;

    ReactDOM.render(<SavingPlanSimulation
        headerIdSimulation={headerIdSimulation}
        infoTableAction={infoTableAction}
        savingPlanSimulationDataAction={savingPlanSimulationDataAction}
        savingPlanChangeDataAction={savingPlanChangeDataAction}
        savingPlanChangeSendAction={savingPlanChangeSendAction}
        nextChangeDateAction={nextChangeDateAction}
        generalInfoAction={generalInfoAction}
    />, simulationWidget);
});
