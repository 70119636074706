import { useFormik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import FormButton, {
    FormButtonVariation,
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import TextareaField from '../../../../../../../../design/1/js/templates/molecules/textarea-field';
import AnswerMessageJsonResponseType from '../../../lib/AnswerMessageJsonResponseType';
import FilterOptionsType from '../../../lib/consultant/FilterOptionsType';
import FilterType from '../../../lib/consultant/FilterType';
import './index.scss';
import getConsultantFromArray from '../../../lib/utils/getConsultantFromArray';

interface ConsultantNewMessageFormProps {
    backToInbox: () => void;
    loggedInConsultantId: number;
    nameByVersIntIdAction: string;
    selectOptions: FilterOptionsType;
    sendMessageAction: string;
    setSelectedValues: (selectValues: FilterType) => void,
}

const ConsultantNewMessageForm = ({
    backToInbox, loggedInConsultantId, nameByVersIntIdAction, selectOptions, sendMessageAction, setSelectedValues,
}: ConsultantNewMessageFormProps) => {
    const baseClassName = 'w-consultantNewMessageForm';

    const loggedInConsultant = getConsultantFromArray(loggedInConsultantId, selectOptions.consultantList);
    const [insuredName, setInsuredName] = useState('');
    const maxFileSize = 8 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'jpe', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);

    const validationSchema = Yup.object().shape({
        versIntId: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        name: Yup.string(),
        title: Yup.string()
            .max(255, () => window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '255'))
            .required(window.sv_resource.get('form_errormsg_string')),
        message: Yup.string()
            .max(2000, () => window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '2000'))
            .required(window.sv_resource.get('form_errormsg_string')),
        subject: Yup.string()
            .required(window.sv_resource.get('form_select_default')),
        status: Yup.string()
            .required(window.sv_resource.get('form_select_default')),
        onBehalfOfConsultantId: Yup.number()
            .when('onBehalfOf', {
                is: true,
                then: Yup.number()
                    .nullable(true)
                    .required(() => window.sv_resource.get('form_select_default')),
            })
            .nullable(true),
        onBehalfOf: Yup.boolean(),
        files: Yup
            .array().of(
                Yup.object().shape({
                    file: Yup.string(),
                    fileName: Yup.string(),
                    fileType: Yup.string(),
                }),
            ),
    });

    const initialValues = Object({
        versIntId: '',
        name: '',
        title: '',
        subject: '',
        message: window.sv_resource.get('plf_messagetool_consultant_message_signature').replace('{{pensionConsultantName}}', loggedInConsultant?.consultantName),
        onBehalfOf: false,
        onBehalfOfConsultantId: null,
        status: selectOptions.statusList[1],
        files: [],
    });

    const handleSubmit = async values => {
        const response: JsonResponse<AnswerMessageJsonResponseType> = await fetch(sendMessageAction, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());

        backToInbox();

        if (response.success) {
            toast.success(window.sv_resource.get('plf_messagetool_consultant_answer_success').replace('{{clientName}}', insuredName));
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_messagetool_answer_error'), response.referenceId));
        }
    };

    const handleVersIntBlur = async (e: ChangeEvent<HTMLInputElement>) => {
        formik.handleBlur(e);

        if (e.target.value.length === 0) {
            return;
        }

        const response: JsonResponse<{ name: string }> = await fetch(nameByVersIntIdAction, {
            method: 'POST',
            body: JSON.stringify(e.target.value),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());

        if (!response.success) {
            if (response.statusCode === 404) {
                formik.setFieldError('versIntId', window.sv_resource.get('plf_messagetool_new_message_recipient_invalid'));
            } else {
                formik.setFieldError('versIntId', window.sv_resource.get('plf_error_unknown'));
                toast.error(formatErrorToastMessage(window.sv_resource.get('plf_error_unknown'), response.referenceId));
            }

            return;
        }

        setInsuredName(response.context.name);

        await formik.setFieldValue('name', response.context.name);
        formik.setFieldError('versIntId', '');
    };

    const onSubmit = async values => {
        const assignee = values.onBehalfOf ? values.onBehalfOfConsultantId : loggedInConsultantId;
        setSelectedValues({
            consultantId: assignee,
            status: values.status,
            subject: values.subject,
            search: '',
        });
        await handleSubmit(values);
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const handleFileChange = event => {
        fileHelper.getFileContent(event.target)
            .then(files => {
                formik.setFieldValue('files', files);
            })
            .catch((error: Error) => {
                formik.setFieldError('files', error.message);
            })
            .finally(() => formik.setFieldTouched('files'));
    };

    const handleOnBehalfOfConsultantIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const onBehalfOfConsultantIdString = typeof e === 'string' ? e : e.target.value;
        const onBehalfOfConsultantId = onBehalfOfConsultantIdString !== '' ? Number.parseInt(onBehalfOfConsultantIdString, 10) : null;
        formik.setFieldValue('onBehalfOfConsultantId', onBehalfOfConsultantId);
    };

    const handleOnBehalfOfCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) {
            formik.setFieldValue('onBehalfOfConsultantId', null);
        }
        formik.handleChange(e);
    };

    return (
        <div className={`${baseClassName}`}>
            <h1>{window.sv_resource.get('plf_messagetool_new_message_title')}</h1>
            <form className={`${baseClassName} row`} onSubmit={formik.handleSubmit}>
                <div className="row gap-xxs">
                    <TextField
                        id="versIntId"
                        label={window.sv_resource.get('plf_messagetool_new_message_recipient_label')}
                        name="versIntId"
                        type="text"
                        className="col-6 col-lg-5"
                        placeholder={window.sv_resource.get('plf_messagetool_new_message_recipient_placeholder')}
                        touched={!!formik.touched.versIntId}
                        errors={formik.errors.versIntId}
                        onBlur={handleVersIntBlur}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        id="name"
                        disabled
                        label=""
                        name="name"
                        type="text"
                        className={`${baseClassName}__insuredName col-6 col-lg-4`}
                        placeholder={window.sv_resource.get('plf_messagetool_new_message_recipient_name_placeholder')}
                        touched={!!formik.touched.name}
                        errors={formik.errors.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={insuredName}
                    />
                </div>
                <div className="row gap-xxs">
                    <TextField
                        id="title"
                        label={window.sv_resource.get('plf_messagetool_new_message_title_label')}
                        name="title"
                        type="text"
                        className="col-7 col-lg-9"
                        placeholder=""
                        touched={!!formik.touched.title}
                        errors={formik.errors.title}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <Select
                        className="col-5 col-lg-3"
                        errors={formik.errors.subject}
                        id="subject"
                        label={window.sv_resource.get('plf_messagetool_new_message_subject_label')}
                        name="subject"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        touched={!!formik.touched.subject}
                        value={formik.values.subject}
                    >
                        <option value="">{window.sv_resource.get('form_select_default')}</option>
                        {
                            selectOptions.subjectList.map(subject => (
                                <option value={subject} key={`subjectFilterOption-${subject}`}>{window.sv_resource.get(`plf_messagetool_theme_${subject}`)}</option>
                            ))
                        }
                    </Select>
                </div>
                <TextareaField
                    id="message"
                    label={window.sv_resource.get('plf_messagetool_new_message_label')}
                    name="message"
                    className={`${baseClassName}__textarea col-12`}
                    placeholder=""
                    touched={!!formik.touched.message}
                    errors={formik.errors.message}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                    value={formik.values.message}
                    rows={8}
                />
                <div className={`${baseClassName}__onBehalfWrapper row gap-xxs`}>
                    <Checkbox
                        className={`${baseClassName}__onBehalfCheck`}
                        checked={formik.values.onBehalfOf}
                        errors={formik.errors.onBehalfOf}
                        touched={!!formik.touched.onBehalfOf}
                        id="onBehalfOf"
                        name="onBehalfOf"
                        onBlur={formik.handleBlur}
                        onChange={handleOnBehalfOfCheckboxChange}
                        label={window.sv_resource.get('plf_messagetool_new_message_on_behalf_of')}
                    />
                    <Select
                        className={`${baseClassName}__onBehalfConsultant col-6 col-lg-3`}
                        disabled={!formik.values.onBehalfOf}
                        errors={formik.errors.onBehalfOfConsultantId}
                        id="onBehalfOfConsultantId"
                        label=""
                        name="onBehalfOfConsultantId"
                        onBlur={formik.handleBlur}
                        onChange={handleOnBehalfOfConsultantIdChange}
                        touched={!!formik.touched.onBehalfOfConsultantId}
                        value={formik.values.onBehalfOfConsultantId ?? ''}
                    >
                        <option disabled value="">{window.sv_resource.get('form_select_default')}</option>
                        {
                            selectOptions.consultantList
                                .filter(consultant => consultant.consultantId !== loggedInConsultantId)
                                .map(consultant => (
                                    <option value={consultant.consultantId} key={`consultantFilterOption-${consultant.consultantId}`}>{consultant.consultantName}</option>
                                ))
                        }
                    </Select>
                </div>
                <div className={`${baseClassName}__uploadSubmitWrapper row`}>
                    <FileUploadField
                        accept="application/pdf, image/png, image/jpeg"
                        className={`${baseClassName}__fileUpload col-12 col-lg-6`}
                        label={window.sv_resource.get('plf_messagetool_client_answer_upload_label')}
                        onChange={handleFileChange}
                        id="files"
                        placeholder=""
                        multiple
                        name="files"
                        errors={formik.touched.files ? formik.errors.files as string : ''}
                        onBlur={formik.handleBlur}
                        touched={!!formik.touched.files}
                        value={formik.values.files}
                    />
                    <div className="col-12 col-lg-6 row justify-content-end">
                        <Select
                            className={`${baseClassName}__statusSelect col-5 text-start`}
                            id="status"
                            errors={formik.errors.status}
                            label={window.sv_resource.get('plf_messagetool_new_message_status_label')}
                            name="status"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            touched={!!formik.touched.status}
                            value={formik.values.status}
                        >
                            <option value="">{window.sv_resource.get('form_select_default')}</option>
                            {
                                selectOptions.statusList.map(status => (
                                    <option value={status} key={`statusFilterOption-${status}`}>{window.sv_resource.get(`plf_messagetool_status_${status}`)}</option>
                                ))
                            }
                        </Select>
                        <div className={`${baseClassName}__buttonWrapper`}>
                            <FormButton
                                className={`${baseClassName}__cancelButton`}
                                handleClick={backToInbox}
                                hasIcon={false}
                                text={window.sv_resource.get('plf_messagetool_new_message_cancel')}
                                type="button"
                                variation={FormButtonVariation.Secondary}
                            />
                            <FormButton
                                className={`${baseClassName}__sendButton`}
                                disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                                hasIcon={false}
                                text={window.sv_resource.get('plf_messagetool_new_message_send')}
                                type="submit"
                                variation={FormButtonVariation.Primary}
                            />
                        </div>
                    </div>
                </div>

            </form>
            <Paragraph>{window.sv_resource.get('plf_messagetool_new_message_required_fields')}</Paragraph>
        </div>
    );
};

export default ConsultantNewMessageForm;
