import React from 'react';
import './index.scss';
import RichContext from '../../../lib/entity/response/RichContext';
import StatusMessage, { MessageType } from '../status-message';
import JsonResponse from '../../../lib/entity/response/JsonResponse';
import StatusMessageVariation from '../status-message/lib/StatusMessageVariation';

interface AnswerTextProps {
    response: JsonResponse<RichContext>;
    variation?: StatusMessageVariation;
}

const AnswerText = ({
    response, variation,
}: AnswerTextProps) => {
    const baseClassName = 'm-answerText';
    return (
        <StatusMessage
            additionalText={response.context.additionalText}
            className={`${baseClassName}`}
            description={response.context.description}
            messageType={!response.success ? MessageType.Error : MessageType.Success}
            referenceId={!response.success ? response.referenceId : undefined}
            title={response.context.title}
            variation={variation}
            link={response.context?.link}
        />
    );
};

AnswerText.defaultProps = {
    variation: StatusMessageVariation.Standard,
};

export default AnswerText;
