import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import { StepProps } from '../../../lib/StepProps';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';

export interface RegistrationType {
    disabilityInsurance: string;
    disabilityInsuranceRegistrationDate: string;
    disabilityInsuranceCanton: string;
}

export const validationSchemaRegistrationStep = Yup.object().shape({
    disabilityInsurance: Yup.string()
        .required(() => window.sv_resource.get('form_errormsg_radio')),
    // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
    disabilityInsuranceRegistrationDate: (Yup.date() as StringSchema)
        .when('disabilityInsurance', {
            is: 'disabilityInsurance-yes',
            // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
            then: (Yup.date() as StringSchema)
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    disabilityInsuranceCanton: Yup.string()
        .when('disabilityInsurance', {
            is: 'disabilityInsurance-yes',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
});

const RegistrationStep = (({
    disabled, errors, handleBack, id, onBlur, onChange, touched, values,
}: StepProps<RegistrationType>) => {
    const baseClassName = 'w-registrationStep';

    const valuesAreSet = values.disabilityInsurance !== '';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_registration_title')}</h2>
            <div className={`${baseClassName}__radioWrapper row gap-xxs`}>
                <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_registration_description')}</Paragraph>
                <RadioButton
                    checked={values.disabilityInsurance.toString() === 'disabilityInsurance-no'}
                    disabled={disabled}
                    errors={errors.disabilityInsurance}
                    id="disabilityInsurance-no"
                    name="disabilityInsurance"
                    onBlur={onBlur}
                    onChange={onChange}
                    touched={touched.disabilityInsurance}
                    label={window.sv_resource.get('plf_onlineform_registration_option_no')}
                />
                <RadioButton
                    checked={values.disabilityInsurance.toString() === 'disabilityInsurance-yes'}
                    disabled={disabled}
                    errors={errors.disabilityInsurance}
                    id="disabilityInsurance-yes"
                    name="disabilityInsurance"
                    onBlur={onBlur}
                    onChange={onChange}
                    touched={touched.disabilityInsurance}
                    label={window.sv_resource.get('plf_onlineform_registration_option_yes')}
                />
                {values.disabilityInsurance.toString() === 'disabilityInsurance-yes'
                    && (
                        <>
                            <DatePickerField
                                className="col-12 col-lg-6"
                                disabled={disabled}
                                label={window.sv_resource.get('plf_onlineform_registration_insurance_date')}
                                icon={IconName.Calendar}
                                id="disabilityInsuranceRegistrationDate"
                                errors={errors.disabilityInsuranceRegistrationDate}
                                touched={touched.disabilityInsuranceRegistrationDate}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={values.disabilityInsuranceRegistrationDate}
                            />
                            <TextField
                                className="col-12 col-lg-6"
                                disabled={disabled}
                                errors={errors.disabilityInsuranceCanton}
                                id="disabilityInsuranceCanton"
                                label={window.sv_resource.get('plf_onlineform_registration_insurance_canton')}
                                name="disabilityInsuranceCanton"
                                onBlur={onBlur}
                                onChange={onChange}
                                placeholder=""
                                touched={touched.disabilityInsuranceCanton}
                                type="text"
                                value={values.disabilityInsuranceCanton}
                            />
                        </>
                    )}
            </div>
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton disabled={disabled || (Object.keys(errors).length > 0 || !valuesAreSet)} text={window.sv_resource.get('plf_onlineform_next_label')} variation={FormButtonVariation.Next} type="submit" />
            </div>
        </div>
    );
});

export default RegistrationStep;
