import React, {
    ChangeEvent, forwardRef, useEffect, useRef,
} from 'react';
import { toast } from 'react-hot-toast';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import { formatDateAndTime } from '../../../../../../../../design/1/js/lib/formatData';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import BtnChevron, { ChevronDirection } from '../../../../../../../../design/1/js/templates/atoms/btn-chevron';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import FilterOptionsType from '../../../lib/consultant/FilterOptionsType';
import MessageType from '../../../lib/MessageType';
import './index.scss';
import {MessageToolView} from "../../../lib/enum/MessageToolView";

interface ConsultantMessageListEntryProps {
    filterOptions: FilterOptionsType;
    message: MessageType;
    updateMessage: (message: MessageType) => void;
    saveMessageUpdateAction: string;
    setSelectedMessage: (message: MessageType) => void;
    switchView: (view: MessageToolView) => void;
}

const ConsultantMessageListEntry = forwardRef<HTMLDivElement, ConsultantMessageListEntryProps>(({
    filterOptions, message, saveMessageUpdateAction, updateMessage, setSelectedMessage, switchView,
}: ConsultantMessageListEntryProps, ref) => {
    const baseClassName = 'm-consultantMessageListEntry';

    const subjectRef = useRef<HTMLSelectElement>();
    const consultantRef = useRef<HTMLSelectElement>();
    const statusRef = useRef<HTMLSelectElement>();

    const subjectError = message.subject !== null ? '' : window.sv_resource.get('form_select_default');

    const messageIsUnread = () => message.answers.some(answer => !answer.read && !answer.fromMPK);

    useEffect(() => {
        subjectRef.current.addEventListener('click', e => {
            e.stopPropagation();
        });
        consultantRef.current.addEventListener('click', e => {
            e.stopPropagation();
        });
        statusRef.current.addEventListener('click', e => {
            e.stopPropagation();
        });
    }, []);

    const updateStatus = (e: ChangeEvent<HTMLSelectElement>) => {
        const tempMessage = { ...message };
        tempMessage.status = e.target.value;
        updateMessage(tempMessage);
        saveMessageUpdate(tempMessage);
    };

    const updateSubject = (e: ChangeEvent<HTMLSelectElement>) => {
        const tempMessage = { ...message };
        tempMessage.subject = e.target.value;
        updateMessage(tempMessage);
        saveMessageUpdate(tempMessage);
    };

    const updateConsultant = (e: ChangeEvent<HTMLSelectElement>) => {
        const tempMessage = { ...message };
        tempMessage.consultantId = +e.target.value;
        updateMessage(tempMessage);
        saveMessageUpdate(tempMessage);
    };

    const saveMessageUpdate = messageToUpdate => {
        fetch(saveMessageUpdateAction, {
            method: 'POST',
            body: JSON.stringify(messageToUpdate),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then((response: JsonResponse) => {
                if (!response.success) {
                    toast.error(formatErrorToastMessage(window.sv_resource.get('plf_error_unknown'), response.referenceId));
                }
            });
    };

    const openConversation = () => {
        setSelectedMessage(message);
        switchView(MessageToolView.CONVERSATION);
    }

    return (
        <div className={`${baseClassName} row`} ref={ref} onClick={openConversation}>
            <div className={`${baseClassName}__button ${open ? '-open' : ''}`}>
                <div className={`${baseClassName}__infoWrapper col-11 ${messageIsUnread() ? '-unread' : ''}`}>
                    <div className={`${baseClassName}__titleSubjectRow col-5`}>
                        <span className={`${baseClassName}__title`}>{message.title}</span>
                        <Select
                            className={`${baseClassName}__select`}
                            id="subject"
                            errors={subjectError}
                            label=""
                            name="subject"
                            ref={subjectRef}
                            touched
                            onChange={updateSubject}
                            value={message.subject ?? ''}
                        >
                            <option value="" disabled>{window.sv_resource.get('plf_messagetool_consultant_subject_label')}</option>
                            {
                                filterOptions.subjectList.map(currentSubject => (
                                    <option value={currentSubject} key={`subjectOption-${currentSubject}`}>{window.sv_resource.get(`plf_messagetool_theme_${currentSubject}`)}</option>
                                ))
                            }
                        </Select>
                    </div>
                    <div className={`${baseClassName}__clientInfoRow col-2`}>
                        <span>{message.client.clientName}</span>
                        <span>{`Vers: ${message.client.clientVersId}`}</span>
                        <span>{`Int: ${message.client.clientVersIntId}`}</span>
                    </div>
                    <div className={`${baseClassName}__dateRow col-2`}>
                        <span>{formatDateAndTime(new Date(message.date))}</span>
                    </div>
                    <div className={`${baseClassName}__consultantInfoRow col-3`}>
                        <Select
                            className={`${baseClassName}__select`}
                            id="consultant"
                            label=""
                            name="consultant"
                            ref={consultantRef}
                            onChange={updateConsultant}
                            value={message.consultantId.toString()}
                        >
                            <option value="" disabled>{window.sv_resource.get('plf_messagetool_consultant_consultant_label')}</option>
                            {
                                filterOptions.consultantList.map(consultant => (
                                    <option value={consultant.consultantId} key={`consultantOption-${consultant.consultantId}`}>{consultant.consultantName}</option>
                                ))
                            }
                        </Select>
                        <Select
                            className={`${baseClassName}__select`}
                            id="status"
                            label=""
                            name="status"
                            ref={statusRef}
                            onChange={updateStatus}
                            value={message.status}
                        >
                            <option value="" disabled>{window.sv_resource.get('plf_messagetool_consultant_status_label')}</option>
                            {
                                filterOptions.statusList.map(currentStatus => (
                                    <option value={currentStatus} key={`statusOption-${currentStatus}`}>{window.sv_resource.get(`plf_messagetool_status_${currentStatus}`)}</option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                <div className={`${baseClassName}__chevronWrapper col-1`}>
                    <BtnChevron className={`${baseClassName}__chevron ${open ? '-rotate' : ''}`} direction={ChevronDirection.Left} standalone />
                </div>
            </div>

        </div>
    );
});

export default ConsultantMessageListEntry;
