import React from 'react';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import './index.scss';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { StepProps } from '../../../lib/StepProps';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import { validateSocialSecurityNumber } from '../../../../../../../../design/1/js/lib/validateData';

export interface MasterDataAsyncProps extends AsyncWidgetProps {
    data?: MasterDataType;
}

export interface MasterDataType extends MasterDataAsyncProps{
    firstname: string;
    lastname: string;
    birthdate: string;
    socialSecurityNumber: string;
}

interface MasterDataStepProps extends StepProps<MasterDataType> {
    isFirstStep?: boolean;
}

export const validationSchemaMasterDataStep = Yup.object().shape({
    firstname: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
    lastname: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
    // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
    birthdate: Yup.date()
        .required(() => window.sv_resource.get('form_errormsg_date')) as StringSchema,
    socialSecurityNumber: Yup.string()
        .test(
            'valid socialSecurityNumber',
            () => window.sv_resource.get('form_errormsg_socialSecurityNumber'),
            value => (validateSocialSecurityNumber(value)),
        )
        .required(() => window.sv_resource.get('form_errormsg_socialSecurityNumber')),
});

const MasterDataStep = ({
    customTitle, disabled = false, errors, id, isFirstStep, handleBack, onBlur, onChange, values,
}: MasterDataStepProps) => {
    const baseClassName = 'w-masterDataStep';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{customTitle ?? window.sv_resource.get('plf_onlineform_masterdata_title')}</h2>
            <div className="row gap-xxs">
                <TextField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    errors={errors.firstname}
                    id="firstname"
                    label={window.sv_resource.get('plf_onlineform_masterdata_firstname')}
                    name="firstname"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder=""
                    touched
                    type="text"
                    value={values.firstname}
                />
                <TextField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    errors={errors.lastname}
                    id="lastname"
                    label={window.sv_resource.get('plf_onlineform_masterdata_lastname')}
                    name="lastname"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder=""
                    touched
                    type="text"
                    value={values.lastname}
                />
                <DatePickerField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    label={window.sv_resource.get('plf_onlineform_masterdata_birthdate')}
                    id="birthdate"
                    errors={errors.birthdate}
                    touched
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values.birthdate}
                />
                <TextField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    errors={errors.socialSecurityNumber}
                    id="socialSecurityNumber"
                    label={window.sv_resource.get('plf_onlineform_masterdata_ahv')}
                    name="socialSecurityNumber"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder=""
                    touched
                    type="text"
                    value={values.socialSecurityNumber}
                />
            </div>
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton className={`${baseClassName}__previousButton ${isFirstStep ? '-hidden' : ''}`} disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton disabled={disabled || (Object.keys(errors).length > 0)} text={window.sv_resource.get('plf_onlineform_next_label')} variation={FormButtonVariation.Next} type="submit" />
            </div>
        </div>
    );
};

MasterDataStep.defaultProps = {
    isFirstStep: false,
};

export default MasterDataStep;
