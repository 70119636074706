import React, { useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import MasterDataStep, { MasterDataType, validationSchemaMasterDataStep } from '../../molecules/master-data';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import CheckStep, { CheckType, validationSchemaCheckStep } from '../../molecules/check';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import {formatSocialSecurityNumber} from '../../../../../../../../design/1/js/lib/formatData';
import CivilStateInfosStep, { CivilStateInfosType, validationSchemaCivilStateInfosStep } from '../../molecules/civil-state-infos';
import UploadCourtDecisionStep, {
    UploadCourtDecisionType,
    validationSchemaUploadCourtDecisionStep,
} from '../../molecules/uplaod-court decision';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import stepRenderer from "../../../lib/stepRenderer";

type CivilStateChangeFormType = MasterDataType & CivilStateInfosType & UploadCourtDecisionType & CheckType;

interface CivilStateChangeFormProps {
    formAction: string;
    handleSubmit: (status: number) => void,
    masterDataAction: string;
}

const CivilStateChangeForm = (({ formAction, handleSubmit, masterDataAction }: CivilStateChangeFormProps) => {
    const baseClassName = 'w-CivilStateChangeForm';
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['masterData', 'civilStateInfo', 'uploadCourtDecision', 'check'];
    const isLastStep = activeStep === steps.length - 1;

    const maxFileSize = 4 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'png', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);

    const initialValues = Object({
        firstname: '',
        lastname: '',
        birthdate: '',
        socialSecurityNumber: '',
        civilStateChangeDate: '',
        nameAfterDivorceOrEndOfRegisteredPartnership: '',
        files: [],
        confirmation: false,
    });

    const validationSchema = [
        validationSchemaMasterDataStep,
        validationSchemaCivilStateInfosStep,
        validationSchemaUploadCourtDecisionStep,
        validationSchemaCheckStep,
    ];

    const currentValidationSchema = validationSchema[activeStep];

    const renderStepContent = (
        step,
        errors: FormikErrors<CivilStateChangeFormType>,
        touched: FormikTouched<CivilStateChangeFormType>,
        onChange,
        onBlur,
        values: CivilStateChangeFormType,
        handleBack,
        handleFileChange?,
    ) => {
        const commonProps = {
            errors,
            touched,
            onChange,
            onBlur,
            values,
            handleBack,
            handleFileChange,
        }

        return stepRenderer(step, [
            (isCurrent) => (
                <AsyncLoader
                    action={masterDataAction}
                    onSuccess={(res: JsonResponse<MasterDataType>) => {
                        initialValues.firstname = res.context.firstname;
                        initialValues.lastname = res.context.lastname;
                        initialValues.birthdate = res.context.birthdate;
                        initialValues.socialSecurityNumber = formatSocialSecurityNumber(res.context.socialSecurityNumber);
                        touched.firstname = true;
                        touched.lastname = true;
                        touched.birthdate = true;
                        touched.socialSecurityNumber = true;
                    }}
                    variation={StatusMessageVariation.Subpage}
                >
                    {() => (
                        <MasterDataStep
                            disabled={!isCurrent}
                            id={steps[0]}
                            isFirstStep
                            {...commonProps}
                        />
                    )}
                </AsyncLoader>
            ),
            (isCurrent) => (
                <CivilStateInfosStep
                    disabled={!isCurrent}
                    id={steps[1]}
                    {...commonProps}
                />
            ),
            (isCurrent) => (
                <UploadCourtDecisionStep
                    disabled={!isCurrent}
                    id={steps[2]}
                    {...commonProps}
                />
            ),
            () => (
                <CheckStep
                    customContent={hasInsuredMultipleIds() && (
                        <div className="row mt-4 mb-5">
                            <StatusMessage
                                customMessageColor={MessageType.Info}
                                messageType={MessageType.Info}
                                description={window.sv_resource.get('plf_yourprofile_master_data_civil_state_description_multiple_insured_ids')}
                                variation={StatusMessageVariation.WithBackground}
                            />
                        </div>
                    )}
                    id={steps[3]}
                    label={window.sv_resource.get('plf_onlineform_civil_state_check_description')}
                    {...commonProps}
                />
            ),
        ]);
    };

    const customOnSubmit = (values, actions) => {
        actions.setSubmitting(false);
        actions.setTouched({});
        setActiveStep(activeStep + 1);
    };

    useEffect(() => {
        scrollTo(`#${steps[activeStep]}`);
    }, [activeStep]);

    const scrollTo = (id: string) => {
        window.location.href = id;
    };

    return (
        activeStep !== steps.length && (
            <FormWithAnswerText
                actionUrl={formAction}
                className={`${baseClassName}`}
                customOnSubmit={isLastStep ? undefined : customOnSubmit}
                handleSubmit={handleSubmit}
                handleValidate={currentValidationSchema}
                initialValues={initialValues}
                name="CivilStateChangeForm"
            >
                {formik => {
                    const handleBack = () => {
                        formik.setErrors({});
                        setActiveStep(activeStep - 1);
                    };

                    const handleFileChange = event => {
                        fileHelper.getFileContent(event.target)
                            .then(files => {
                                formik.setFieldValue('files', files);
                            })
                            .catch((error: Error) => {
                                formik.setFieldError('files', error.message);
                            })
                            .finally(() => formik.setFieldTouched('files'));
                    };

                    return (
                        <>
                            { renderStepContent(activeStep, formik.errors, formik.touched, formik.handleChange, formik.handleBlur, formik.values, handleBack, handleFileChange) }
                        </>
                    );
                }}
            </FormWithAnswerText>
        )
    );
});

export default CivilStateChangeForm;
