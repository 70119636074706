import React from 'react';
import PensionCertificateSimulationForm from '../pension-certificate-simulation-form';
import Simulation from '../simulation';

interface PensionCertificateSimulationProps {
    calculatingAction: string;
    headerIdSimulation: string;
}

const PensionCertificateSimulation = ({
    calculatingAction, headerIdSimulation,
}: PensionCertificateSimulationProps) => {
    const baseClassName = 'w-pensionCertificateSimulation';

    return (
        <div className={`${baseClassName}`}>
            <Simulation
                simulationForm={() => <PensionCertificateSimulationForm actionUrl={calculatingAction} headerId={headerIdSimulation} />}
            />
        </div>
    );
};

export default PensionCertificateSimulation;
