import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import { StepProps } from '../../../lib/StepProps';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';

export interface CivilStateInfosType {
    civilStateChangeDate: string;
    nameAfterDivorceOrEndOfRegisteredPartnership: string;
}

export const validationSchemaCivilStateInfosStep = Yup.object().shape({
    // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
    civilStateChangeDate: Yup.date()
        .required(() => window.sv_resource.get('form_errormsg_date')) as StringSchema,
    nameAfterDivorceOrEndOfRegisteredPartnership: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
});

const CivilStateInfosStep = (({
    disabled, errors, handleBack, id, touched, onChange, onBlur, values,
}: StepProps<CivilStateInfosType>) => {
    const baseClassName = 'w-civilStateInfosStep';

    const valuesAreSet = values.civilStateChangeDate !== '' && values.nameAfterDivorceOrEndOfRegisteredPartnership !== '';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_civil_state_info_title')}</h2>
            <div className="row gap-lg-s">
                <DatePickerField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    label={window.sv_resource.get('plf_onlineform_civil_state_info_change_date')}
                    icon={IconName.Calendar}
                    id="civilStateChangeDate"
                    errors={errors.civilStateChangeDate}
                    touched={touched.civilStateChangeDate}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values.civilStateChangeDate}
                />
                <TextField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    errors={errors.nameAfterDivorceOrEndOfRegisteredPartnership}
                    id="nameAfterDivorceOrEndOfRegisteredPartnership"
                    label={window.sv_resource.get('plf_onlineform_civil_state_info_change_name_after')}
                    name="nameAfterDivorceOrEndOfRegisteredPartnership"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder=""
                    touched={touched.nameAfterDivorceOrEndOfRegisteredPartnership}
                    type="text"
                    value={values.nameAfterDivorceOrEndOfRegisteredPartnership}
                />
            </div>
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton disabled={disabled || (Object.keys(errors).length > 0 || !valuesAreSet)} text={window.sv_resource.get('plf_onlineform_next_label')} variation={FormButtonVariation.Next} type="submit" />
            </div>
        </div>
    );
});

export default CivilStateInfosStep;
