import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import { StepProps } from '../../../lib/StepProps';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import FileContent from '../../../../../../../../design/1/js/lib/FileContent';

export interface UploadCourtDecisionType {
    files: FileContent[];
}

export const validationSchemaUploadCourtDecisionStep = Yup.object().shape({
    files: Yup
        .array().of(
            Yup.object().shape({
                file: Yup.string(),
                fileName: Yup.string(),
                fileType: Yup.string(),
            }),
        ).min(1, () => window.sv_resource.get('form_errormsg_upload'))
        .required(() => window.sv_resource.get('form_errormsg_upload')),
});

const UploadCourtDecisionStep = (({
    disabled, errors, handleBack, id, handleFileChange, touched, onBlur, values,
}: StepProps<UploadCourtDecisionType>) => {
    const baseClassName = 'w-uploadCourtDecisionStep';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_civil_state_document_title')}</h2>
            <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_civil_state_document_description')}</Paragraph>
            <div className="row gap-lg-s">
                <FileUploadField
                    accept="application/pdf, image/jpeg, image/png"
                    disabled={disabled}
                    label={window.sv_resource.get('plf_onlineform_civil_state_document_label')}
                    onChange={handleFileChange}
                    id="files"
                    placeholder=""
                    name="files"
                    errors={touched.files ? errors.files as string : ''}
                    onBlur={onBlur}
                    touched={!!touched.files}
                    value={values.files}
                />
            </div>
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton disabled={disabled || (Object.keys(errors).length > 0 || values.files.length === 0)} text={window.sv_resource.get('plf_onlineform_next_label')} variation={FormButtonVariation.Next} type="submit" />
            </div>
        </div>
    );
});

export default UploadCourtDecisionStep;
