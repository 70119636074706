import React, { ChangeEvent, useState } from 'react';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import FilterType from '../../../lib/consultant/FilterType';
import FilterOptionsType from '../../../lib/consultant/FilterOptionsType';
import { IconPosition } from '../../../../../../../../design/1/js/templates/atoms/icon';
import SearchInput from '../../../../../../../../design/1/js/templates/molecules/search-input';

interface ConsultantMessageFilterProps {
    filter: FilterType;
    filterOptions: FilterOptionsType;
    updateFilter: (filter: FilterType) => void;
}

const ConsultantMessageFilter = ({
    filterOptions,
    filter,
    updateFilter,
}: ConsultantMessageFilterProps) => {
    const baseClassName = 'm-consultantMessageFilter';

    const [currentFilter, setCurrentFilter] = useState(filter);

    const changeConsultantId = (e: ChangeEvent<HTMLSelectElement>) => {
        const consultantId = e.target.value;

        const tempFilter = { ...currentFilter };
        tempFilter.consultantId = consultantId !== 'all' ? +consultantId : null;

        setCurrentFilter(tempFilter);
        updateFilter(tempFilter);
    };

    const changeSubject = (e: ChangeEvent<HTMLSelectElement>) => {
        const subject = e.target.value;

        const tempFilter = { ...currentFilter };
        tempFilter.subject = subject !== 'all' ? subject : null;

        setCurrentFilter(tempFilter);
        updateFilter(tempFilter);
    };

    const changeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
        const status = e.target.value;

        const tempFilter = { ...currentFilter };
        tempFilter.status = status !== 'all' ? status : null;

        setCurrentFilter(tempFilter);
        updateFilter(tempFilter);
    };

    const executeSearch = () => {
        updateFilter(currentFilter);
    }

    const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const tempFilter = { ...currentFilter };
        tempFilter.search = value;

        setCurrentFilter(tempFilter);
    }

    const clearSearch = () => {
        const tempFilter = { ...currentFilter };
        tempFilter.search = '';

        setCurrentFilter(tempFilter);
        updateFilter(tempFilter);
    }

    return (
        <div className={`${baseClassName} row gap-xxs`}>
            <div className={`${baseClassName}__filter col-2`}>
                <Select
                    id="status"
                    label={window.sv_resource.get('plf_messagetool_consultant_status_label')}
                    name="status"
                    onChange={changeStatus}
                    value={currentFilter.status ?? 'all'}
                >
                    <option value="all">{window.sv_resource.get('plf_messagetool_consultant_select_all_label')}</option>
                    {
                        filterOptions.statusList.map(status => (
                            <option value={status} key={`statusFilterOption-${status}`}>{window.sv_resource.get(`plf_messagetool_status_${status}`)}</option>
                        ))
                    }
                </Select>
            </div>
            <div className={`${baseClassName}__filter col-2`}>
                <Select
                    id="subject"
                    label={window.sv_resource.get('plf_messagetool_consultant_subject_label')}
                    name="subject"
                    onChange={changeSubject}
                    value={currentFilter.subject ?? 'all'}
                >
                    <option value="all">{window.sv_resource.get('plf_messagetool_consultant_select_all_label')}</option>
                    {
                        filterOptions.subjectList.map(subject => (
                            <option value={subject} key={`subjectFilterOption-${subject}`}>{window.sv_resource.get(`plf_messagetool_theme_${subject}`)}</option>
                        ))
                    }
                </Select>
            </div>
            <div className={`${baseClassName}__filter col-3`}>
                <Select
                    id="consultant"
                    label={window.sv_resource.get('plf_messagetool_consultant_consultant_label')}
                    name="consultant"
                    onChange={changeConsultantId}
                    value={currentFilter.consultantId?.toString() ?? 'all'}
                >
                    <option value="all">{window.sv_resource.get('plf_messagetool_consultant_select_all_label')}</option>
                    {
                        filterOptions.consultantList.map(currentConsultant => (
                            <option value={currentConsultant.consultantId} key={`consultantFilterOption-${currentConsultant.consultantId}`}>{currentConsultant.consultantName}</option>
                        ))
                    }
                </Select>
            </div>
            <div className={`${baseClassName}__filter col-5`}>
                <SearchInput
                    id={'search'}
                    className={`${baseClassName}__field col-12`}
                    label={window.sv_resource.get('plf_messagetool_consultant_search_label')}
                    placeholder={window.sv_resource.get('plf_messagetool_consultant_search_placeholder')}
                    name='search'
                    value={currentFilter.search}
                    iconPosition={IconPosition.Right}
                    onIconClick={clearSearch}
                    onChange={changeSearch}
                    onSubmit={executeSearch}
                />
            </div>
        </div>
    );
};

export default ConsultantMessageFilter;
