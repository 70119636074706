import React, {useState} from 'react';
import './index.scss';
import {toast} from 'react-hot-toast';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import ToggleSwitch from '../../../../../../../../design/1/js/templates/atoms/form-fields/toggle-switch';
import DocumentDeliveryType from '../../../lib/entity/DocumentDeliveryType';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import {AsyncWidgetProps} from '../../../../../base/ts/lib/AsyncWidgetProps';
import DocumentDeliveryActionType from '../../../lib/entity/DocumentDeliveryActionType';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

interface DocumentDeliveryProps extends AsyncWidgetProps {
    documentDeliveryActions: DocumentDeliveryActionType;
    className?: string;
}

const DocumentDelivery = ({
    action,
    documentDeliveryActions,
    className,
}: DocumentDeliveryProps) => {
    const baseClassName = 'w-yourProfile-documentDelivery';

    const [isPensionDocumentsEnabled, setPensionDocumentsEnabled] = useState(false);
    const [isMpkFlashEnabled, setMpkFlashEnabled] = useState(false);

    const sendRequest = (enabled: boolean, actionTo: string): Promise<JsonResponse> => (
        fetch(actionTo, {
            method: 'POST',
            body: JSON.stringify(enabled),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
    );

    const handleDocumentDeliveryChange = async (enabled: boolean) => {
        setPensionDocumentsEnabled(enabled);

        /* Request must be sent with !enabled because enabled is the state of the toggleSwitch, but the backend needs the exact opposite */
        const response: JsonResponse = await sendRequest(!enabled, documentDeliveryActions.pensionDocuments);

        if (response.success) {
            toast.success(window.sv_resource.get('plf_yourprofile_documentdelivery_request_success'));
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_yourprofile_documentdelivery_request_error'), response.referenceId));
            setPensionDocumentsEnabled(!enabled);
        }
    };

    const handleMpkFlashChange = async (enabled: boolean) => {
        setMpkFlashEnabled(enabled);

        const response: JsonResponse = await sendRequest(enabled, documentDeliveryActions.mpkFlash);

        if (response.success) {
            toast.success(window.sv_resource.get('plf_yourprofile_documentdelivery_request_success'));
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_yourprofile_documentdelivery_request_error'), response.referenceId));
            setMpkFlashEnabled(!enabled);
        }
    };

    return (
        <div className={`${baseClassName} ${className}`}>
            <AsyncLoader
                action={action}
                onSuccess={res => {
                    setPensionDocumentsEnabled(res.context.pensionDocuments.state === 'on');
                    setMpkFlashEnabled(res.context.mpkFlash.state === 'on');
                }}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={window.sv_resource.get('plf_yourprofile_documentdelivery_title')}>{window.sv_resource.get('plf_yourprofile_documentdelivery_title')}</h2>
                        <RichText className={`${baseClassName}__description`} inline={false} text={window.sv_resource.get('plf_yourprofile_documentdelivery_description')} />
                        {lazyChildren}
                    </>
                )}
            >
                {(documentData: DocumentDeliveryType) => (
                    <>
                        <div className="row gap-xxs">
                            <Paragraph className="col-5 col-sm-6 light-text">{documentData.pensionDocuments.label}</Paragraph>
                            <ToggleSwitch
                                checked={isPensionDocumentsEnabled}
                                className="col-7 col-sm-6"
                                onChange={handleDocumentDeliveryChange}
                                states={documentData.pensionDocuments.options}
                            />
                            <Paragraph className="col-5 col-sm-6 light-text">{documentData.mpkFlash.label}</Paragraph>
                            <ToggleSwitch
                                checked={isMpkFlashEnabled}
                                className="col-7 col-sm-6"
                                onChange={handleMpkFlashChange}
                                states={documentData.mpkFlash.options}
                            />
                        </div>
                        {hasInsuredMultipleIds() && (
                            <div className="row gap-xxs">
                                <StatusMessage
                                    customMessageColor={MessageType.Success}
                                    messageType={MessageType.Info}
                                    description={window.sv_resource.get('plf_yourprofile_documentdelivery_description_multiple_insured_ids')}
                                    variation={StatusMessageVariation.WithBackground}
                                />
                            </div>
                        )}
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

DocumentDelivery.defaultProps = {
    className: '',
};

export default DocumentDelivery;
