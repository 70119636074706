import React, { useEffect, useRef, useState } from "react";
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import { getIsoDate } from '../../../../../../../../design/1/js/lib/formatData';
import usePdfDownload from '../../../../../../../../design/1/js/lib/hooks/usePdfDownload';
import LoadSpinner from "../../../../../../../../design/1/js/templates/atoms/load-spinner";

interface PensionCertificateSimulationFormProps {
    headerId: string;
    actionUrl: string;
}

const PensionCertificateSimulationForm = ({
    actionUrl, headerId,
}: PensionCertificateSimulationFormProps) => {
    const baseClassName = 'w-pensionCertificateSimulationForm';
    const [isLoading, setLoading] = useState(false);
    const [datePickerError, setDatePickerError] = useState(null);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [maxDatePickerDate, setMaxDatePickerDate] = useState<Date>(null);

    const validationSchema = Yup.object().shape({
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        simulationDate: Yup.date()
            .test('isFuture', window.sv_resource.get('form_errormsg_date_isNotInFuture'), (val: Date) => val.getTime() >= (new Date()).setHours(0, 0, 0, 0))
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
    });

    const initialValues = Object.freeze({
        simulationDate: getIsoDate(new Date()),
    });

    const onSubmit = usePdfDownload(actionUrl, setLoading, undefined, true);

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });
    useEffect(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 100);
        setMaxDatePickerDate(date);
    }, []);


    const handleIsValid = (isValid: boolean) => {
        setDatePickerError(isValid ? null : window.sv_resource.get('form_errormsg_date_between_now_and_date').replace('{{max}}', maxDatePickerDate.toLocaleDateString('de-CH')));
    };

    return (
        <div className={`${baseClassName}`}>
            <h2 id={headerId} className={`${isLoading ? 'd-none' : ''}`}>{window.sv_resource.get('plf_simulation_title')}</h2>
            { isLoading &&
                <LoadSpinner />
            }
            <Paragraph className={`${isLoading ? 'd-none' : ''}`}>{window.sv_resource.get('plf_simulation_pension_certificate_description')}</Paragraph>
            <form name="pensionCertificateSimulation" onSubmit={formik.handleSubmit} className={`${isLoading ? 'd-none' : ''}`}>
                <>
                    <div className="row gap-xxs">
                        <DatePickerField
                            className="col-12 col-lg-6"
                            minDate={new Date()}
                            maxDate={maxDatePickerDate}
                            errors={datePickerError ?? formik.errors.simulationDate}
                            icon={IconName.Calendar}
                            id="simulationDate"
                            label={window.sv_resource.get('plf_simulation_pension_certificate_label_advance')}
                            ref={datePickerRef}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onIsValid={handleIsValid}
                            touched
                            value={formik.values.simulationDate}
                        />
                    </div>
                    <div className={`${baseClassName}__submitWrapper row`}>
                        <FormButton
                            disabled={Object.keys(formik.errors).length > 0 || datePickerError !== null || isLoading}
                            hasIcon={false}
                            text={window.sv_resource.get('plf_simulation_generate_button_label')}
                            type="submit"
                            variation={FormButtonVariation.Primary}
                        />
                    </div>
                </>
            </form>
        </div>
    );
};

export default PensionCertificateSimulationForm;
