import React, {useRef, useState} from 'react';
import {toast} from 'react-hot-toast';
import * as yup from 'yup';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import FormButton, {
    FormButtonVariation
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import Form from '../../../../../../../../design/1/js/templates/organisms/form';
import ContactDataType from '../../../lib/entity/ContactDataType';
import './index.scss';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import {AsyncWidgetProps} from '../../../../../base/ts/lib/AsyncWidgetProps';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

interface ContactDataProps extends AsyncWidgetProps{
    actionEmail: string;
    actionPhone: string;
    className?: string;
}

const ContactData = ({
    action, actionEmail, actionPhone, className,
}: ContactDataProps) => {
    const baseClassName = 'w-yourProfile-contactData';

    const phoneInputRef = useRef<HTMLInputElement>(null);
    const mailInputRef = useRef<HTMLInputElement>(null);

    const [editingPhone, setEditingPhone] = useState(false);
    const [editingMail, setEditingMail] = useState(false);
    const [showMailVerifyInfo, setShowMailVerifyInfo] = useState(false);

    const schemaPhone = yup.object().shape({
        phone: yup.string()
            .matches(/^\+?[0-9\s]+$/, window.sv_resource.get('form_errormsg_phone'))
            .max(25, window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '25'))
            .required(window.sv_resource.get('form_errormsg_phone')),
    });

    const schemaEmail = yup.object().shape({
        email: yup.string()
            .email(window.sv_resource.get('form_errormsg_email'))
            .required(window.sv_resource.get('form_errormsg_email')),
    });

    const handleResponsePhone = (response: JsonResponse) => {
        if (response.success) {
            toast.success(window.sv_resource.get('plf_yourprofile_contactdata_phone_request_success'));
            setEditingPhone(false);
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_yourprofile_contactdata_phone_request_error'), response.referenceId));
        }
    };

    const handleResponseEmail = (response: JsonResponse) => {
        if (response.success) {
            setEditingMail(false);
            toast.success(window.sv_resource.get('plf_yourprofile_contactdata_email_request_success'));
            setShowMailVerifyInfo(true);
        } else {
            toast.error(
                formatErrorToastMessage(
                    window.sv_resource.get(
                        response.statusCode === 429 ? 'plf_yourprofile_contactdata_email_request_rate_limit' :'plf_yourprofile_contactdata_email_request_error'
                    ),
                    response.referenceId
                ),
            );
        }
    };

    return (
        <div className={`${baseClassName} ${className}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={window.sv_resource.get('plf_yourprofile_contactdata')}>{window.sv_resource.get('plf_yourprofile_contactdata')}</h2>
                        {lazyChildren}
                    </>
                )}
            >
                {(contactData: ContactDataType) => (
                    <>
                        <Form actionUrl={actionPhone} name="phone" initialValues={{ phone: contactData.phone }} handleResponse={handleResponsePhone} validationSchema={schemaPhone}>
                            {formik => (
                                <div className="row gap-xxs">
                                    {
                                        contactData.phone !== null && (
                                            <div className="col-12 col-lg-6">
                                                <TextField
                                                    className={`${baseClassName}__field`}
                                                    disabled={!contactData.isPhoneEditable || !editingPhone}
                                                    errors={formik.errors.phone}
                                                    id="phone"
                                                    label={window.sv_resource.get('plf_yourprofile_contactdata_phone')}
                                                    name="phone"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    placeholder={window.sv_resource.get('plf_yourprofile_contactdata_phone_placeholder')}
                                                    ref={phoneInputRef}
                                                    touched={formik.touched.phone}
                                                    type="tel"
                                                    value={formik.values.phone}
                                                />
                                            </div>
                                        )
                                    }
                                    <div className="col-12 col-lg-6">
                                        {contactData.isPhoneEditable && (
                                            editingPhone ? (
                                                <FormButton
                                                    className={`${baseClassName}__button`}
                                                    hasIcon={false}
                                                    text={window.sv_resource.get('plf_yourprofile_contactdata_save')}
                                                    type="submit"
                                                    variation={FormButtonVariation.Primary}
                                                />
                                            ) : (
                                                <FormButton
                                                    className={`${baseClassName}__button`}
                                                    hasIcon={false}
                                                    handleClick={event => {
                                                        setEditingPhone(true);
                                                        setTimeout(() => {
                                                            phoneInputRef.current?.focus();
                                                        }, 0);
                                                        event.preventDefault();
                                                    }}
                                                    text={window.sv_resource.get('plf_yourprofile_contactdata_edit')}
                                                    type="button"
                                                    variation={FormButtonVariation.Primary}
                                                />
                                            )
                                        )}
                                    </div>
                                    {
                                        (contactData.isPhoneEditable && hasInsuredMultipleIds()) && (
                                            <div className="col-12 mb-4">
                                                <StatusMessage
                                                    customMessageColor={MessageType.Info}
                                                    messageType={MessageType.Info}
                                                    description={window.sv_resource.get('plf_yourprofile_contactdata_description_phone_multiple_insured_ids')}
                                                    variation={StatusMessageVariation.WithBackground}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            )}
                        </Form>
                        <Form actionUrl={actionEmail} name="email" initialValues={{ email: contactData.email }} handleResponse={handleResponseEmail} validationSchema={schemaEmail}>
                            {formik => (
                                <div className="row gap-xxs">
                                    <div className="col-12 col-lg-6">
                                        <TextField
                                            className={`${baseClassName}__field`}
                                            disabled={!editingMail}
                                            errors={formik.errors.email}
                                            id="email"
                                            label={window.sv_resource.get('plf_yourprofile_contactdata_email')}
                                            name="email"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={window.sv_resource.get('plf_yourprofile_contactdata_email_placeholder')}
                                            ref={mailInputRef}
                                            touched={formik.touched.email}
                                            type="email"
                                            value={formik.values.email}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        {editingMail ? (
                                            <FormButton
                                                className={`${baseClassName}__button`}
                                                hasIcon={false}
                                                text={window.sv_resource.get('plf_yourprofile_contactdata_save')}
                                                variation={FormButtonVariation.Primary}
                                                disabled={formik.isSubmitting}
                                                hidden={contactData.email === formik.values.email}
                                                type="submit"
                                            />
                                        ) : (
                                            <FormButton
                                                className={`${baseClassName}__button`}
                                                hasIcon={false}
                                                handleClick={event => {
                                                    setEditingMail(true);
                                                    setTimeout(() => {
                                                        mailInputRef.current?.focus();
                                                    }, 0);
                                                    event.preventDefault();
                                                }}
                                                text={window.sv_resource.get('plf_yourprofile_contactdata_edit')}
                                                type="button"
                                                variation={FormButtonVariation.Primary}
                                            />
                                        )}
                                    </div>
                                    <div className="col-12">
                                    {
                                        showMailVerifyInfo && (<StatusMessage
                                            customMessageColor={MessageType.Info}
                                            messageType={MessageType.Info}
                                            description={window.sv_resource.get('plf_yourprofile_contactdata_email_change_info')}
                                            variation={StatusMessageVariation.WithBackground}
                                        />)
                                    }
                                    </div>
                                    {hasInsuredMultipleIds() && (
                                        <div className="col-12 mb-4">
                                            <StatusMessage
                                                customMessageColor={MessageType.Success}
                                                messageType={MessageType.Info}
                                                description={window.sv_resource.get('plf_yourprofile_contactdata_description_email_multiple_insured_ids')}
                                                variation={StatusMessageVariation.WithBackground}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </Form>
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

ContactData.defaultProps = {
    className: '',
};

export default ContactData;
