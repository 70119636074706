import React from 'react';
import './index.scss';
import HeaderTop from '../../molecules/header-top';
import HeaderBottom, { HeaderBottomProps } from '../../molecules/header-bottom';
import ToastContainer from '../../molecules/toast-container';

const Header = ({
    authenticated, isLeaving, navigationItems, newDocumentsAction, newMessagesAction,
}:HeaderBottomProps) => {
    const baseClassName = 'o-header';
    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__borderTop`} />
            <div className={`${baseClassName}__wrapper`}>
                <HeaderTop authenticated={authenticated} />
                <HeaderBottom authenticated={authenticated} isLeaving={isLeaving} navigationItems={navigationItems} newDocumentsAction={newDocumentsAction} newMessagesAction={newMessagesAction} />
                <ToastContainer />
            </div>
        </div>
    );
};

export default Header;
