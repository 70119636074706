import React from "react";
function useStatefulRef(initialVal = null) {
  let [cur, setCur] = React.useState(initialVal);
  const { current: ref } = React.useRef({
    current: cur
  });
  Object.defineProperty(ref, "current", {
    get: () => cur,
    set: (value) => {
      if (!Object.is(cur, value)) {
        cur = value;
        setCur(value);
      }
    }
  });
  return ref;
}
function useForwardedRef(forwardedRef) {
  const innerRef = useStatefulRef(null);
  React.useEffect(() => {
    if (!forwardedRef)
      return;
    if (typeof forwardedRef === "function") {
      forwardedRef(innerRef.current);
    } else {
      forwardedRef.current = innerRef.current;
    }
  });
  return innerRef;
}
export { useForwardedRef as default };
