import React, { ChangeEvent } from 'react';
import './index.scss';
import * as Yup from 'yup';
import { formatIBAN } from '../../../../../../../../design/1/js/lib/formatData';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import { StepProps } from '../../../lib/StepProps';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import FileContent from '../../../../../../../../design/1/js/lib/FileContent';
import { bicSwiftRegex, validateIBAN } from '../../../../../../../../design/1/js/lib/validateData';
import BankTransferData from '../../../lib/type/BankTransferData';

export interface TransferVeryLowWageType {
    domestic: string;
    accountType: string;
    iban: string;
    accountNr: string;
    bic: string;
    currency: string;
    bankName: string;
    street1: string;
    street2: string;
    zip: string;
    city: string;
    country: string;
    files: FileContent[];
}

export const validationSchemaTransferStep = Yup.object().shape({
    domestic: Yup.string().required(() => window.sv_resource.get('form_errormsg_radio')),
    accountType: Yup.string().oneOf(['iban', 'accountNr']),
    iban: Yup.string()
        .when(['domestic', 'accountType'], {
            is: (domestic, accountType) => domestic === 'domestic-yes' || accountType === 'iban',
            then: Yup.string()
                .test(
                    'valid iban',
                    () => window.sv_resource.get('form_errormsg_iban'),
                    value => (validateIBAN(value)),
                )
                .required(() => window.sv_resource.get('form_errormsg_iban')),
        }),
    accountNr: Yup.string()
        .when(['domestic', 'accountType'], {
            is: (domestic, accountType) => domestic === 'domestic-no' && accountType === 'accountNr',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    bic: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .matches(bicSwiftRegex, () => window.sv_resource.get('form_errormsg_bic'))
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    currency: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    bankName: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    street1: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    street2: Yup.string(),
    zip: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    city: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    country: Yup.string()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.string()
                .required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    files: Yup.array()
        .when('domestic', {
            is: 'domestic-no',
            then: Yup.array()
                .of(Yup.object().shape({
                    file: Yup.string(),
                    fileName: Yup.string(),
                    fileType: Yup.string(),
                }))
                .min(1, () => window.sv_resource.get('form_errormsg_upload'))
                .required(() => window.sv_resource.get('form_errormsg_upload')),
        }),
});

type TransferVeryLowWageStepProps = StepProps<TransferVeryLowWageType> & {
    bankTransferData: BankTransferData
};

const TransferVeryLowWageStep = (({
    bankTransferData, disabled, errors, handleBack, handleFileChange, id, touched, onChange, onBlur, values,
}: TransferVeryLowWageStepProps) => {
    const baseClassName = 'w-transferVeryLowWageStep';

    const valuesAreSet = values.domestic === 'domestic-yes' ? (
        values.iban !== ''
    ) : (
        (values.accountType === 'iban' ? (
            values.iban !== ''
        ) : (
            values.accountNr !== ''
        ))
        && values.bic !== ''
        && values.currency !== ''
        && values.bankName !== ''
        && values.street1 !== ''
        && values.zip !== ''
        && values.city !== ''
        && values.country !== ''
        && values.files.length > 0
    );

    const handleIbanBlur = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== '' && validateIBAN(e.target.value)) {
            // @ts-ignore
            // TS says `void` has no call signatures, but when calling `onChange` with a string it returns a function
            onChange('iban')(formatIBAN(e.target.value));
        }
        onBlur(e);
    };

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_transfer_cash_title')}</h2>
            <div className="row gap-xxs">
                <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_transfer_cash_description')}</Paragraph>
            </div>
            <div className="row gap-xxs">
                <div className={`${baseClassName}__radioWrapper`}>
                    <RadioButton
                        checked={values.domestic.toString() === 'domestic-yes'}
                        disabled={disabled}
                        errors={errors.domestic}
                        id="domestic-yes"
                        name="domestic"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.domestic}
                        label={window.sv_resource.get('plf_onlineform_transfer_cash_domestic_option_ch')}
                    />
                    <RadioButton
                        checked={values.domestic.toString() === 'domestic-no'}
                        disabled={disabled}
                        errors={errors.domestic}
                        id="domestic-no"
                        name="domestic"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.domestic}
                        label={window.sv_resource.get('plf_onlineform_transfer_cash_domestic_option_abroad')}
                    />
                </div>
            </div>
            {values.domestic === 'domestic-yes' ? (
                <div className="row gap-xxs">
                    <TextField
                        className="col-12 col-lg-6"
                        disabled={disabled}
                        errors={errors.iban}
                        id="iban"
                        label={window.sv_resource.get('plf_servicesform_changeofaccount_iban_label')}
                        name="iban"
                        onBlur={handleIbanBlur}
                        onChange={onChange}
                        placeholder=""
                        touched={touched.iban}
                        type="text"
                        value={values.iban}
                    />
                </div>
            ) : (
                <>
                    <div className="row gap-xxs">
                        <div className={`${baseClassName}__radioWrapper`}>
                            <RadioButton
                                checked={values.accountType === 'iban'}
                                errors={errors.accountType}
                                id="iban"
                                name="accountType"
                                onBlur={onBlur}
                                onChange={onChange}
                                touched={!!touched.accountType}
                                label={window.sv_resource.get('plf_servicesform_changeofaccount_iban_label')}
                            />
                            <RadioButton
                                checked={values.accountType === 'accountNr'}
                                errors={errors.accountType}
                                id="accountNr"
                                name="accountType"
                                onBlur={onBlur}
                                onChange={onChange}
                                touched={!!touched.accountType}
                                label={window.sv_resource.get('plf_servicesform_changeofaccount_accountnr_label')}
                            />
                        </div>
                    </div>
                    <div className="row gap-xxs">
                        {values.accountType === 'iban' ? (
                            <TextField
                                className="col-12 col-lg-6"
                                disabled={disabled}
                                errors={errors.iban}
                                id="iban"
                                label={window.sv_resource.get('plf_servicesform_changeofaccount_iban_label')}
                                name="iban"
                                onBlur={handleIbanBlur}
                                onChange={onChange}
                                placeholder=""
                                touched={touched.iban}
                                type="text"
                                value={values.iban}
                            />
                        ) : (
                            <TextField
                                className="col-12 col-lg-6"
                                disabled={disabled}
                                errors={errors.accountNr}
                                id="accountNr"
                                label={window.sv_resource.get('plf_servicesform_changeofaccount_accountnr_label')}
                                name="accountNr"
                                onBlur={onBlur}
                                onChange={onChange}
                                placeholder=""
                                touched={touched.accountNr}
                                type="text"
                                value={values.accountNr}
                            />
                        )}
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.bic}
                            id="bic"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_bic')}
                            name="bic"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.bic}
                            type="text"
                            value={values.bic}
                        />
                        <Select
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            id="currency"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_currency')}
                            name="currency"
                            onBlur={onBlur}
                            onChange={onChange}
                            value={values.currency}
                        >
                            <option value="" disabled>{window.sv_resource.get('form_select_default')}</option>
                            {bankTransferData.currencies.map(currency => (
                                <option key={currency.name} value={currency.name}>
                                    {currency.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="row gap-xxs">
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.bankName}
                            id="bankName"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_name_of_bank')}
                            name="bankName"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.bankName}
                            type="text"
                            value={values.bankName}
                        />
                    </div>
                    <div className="row gap-xxs">
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.street1}
                            id="street1"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_street1')}
                            name="street1"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.street1}
                            type="text"
                            value={values.street1}
                        />
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.street2}
                            id="street2"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_street2')}
                            name="street2"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.street2}
                            type="text"
                            value={values.street2}
                        />
                        <TextField
                            className="col-4 col-lg-2"
                            disabled={disabled}
                            errors={errors.zip}
                            id="zip"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_zip')}
                            name="zip"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.zip}
                            type="text"
                            value={values.zip}
                        />
                        <TextField
                            className="col-8 col-lg-4"
                            disabled={disabled}
                            errors={errors.city}
                            id="city"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_city')}
                            name="city"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.city}
                            type="text"
                            value={values.city}
                        />
                        <Select
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.country}
                            id="country"
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_country')}
                            name="country"
                            onBlur={onBlur}
                            onChange={onChange}
                            touched={touched.country}
                            value={values.country}
                        >
                            <option value="" disabled>{window.sv_resource.get('form_select_default')}</option>
                            {
                                bankTransferData.countries.map(entry => (
                                    <option value={entry.iso} key={entry.iso}>
                                        {entry.label}
                                    </option>
                                ))
                            }
                        </Select>
                        <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_documents_description')}</Paragraph>
                        <FileUploadField
                            accept="application/pdf, image/jpeg, image/png"
                            disabled={disabled}
                            label={window.sv_resource.get('plf_onlineform_transfer_cash_documents_iban')}
                            onChange={handleFileChange}
                            id="files"
                            placeholder=""
                            multiple
                            name="files"
                            errors={touched.files ? errors.files as string : ''}
                            onBlur={onBlur}
                            touched={!!touched.files}
                            value={values.files}
                        />
                    </div>
                </>
            )}
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton disabled={disabled || (Object.keys(errors).length > 0 || !valuesAreSet)} text={window.sv_resource.get('plf_onlineform_next_label')} variation={FormButtonVariation.Next} type="submit" />
            </div>
        </div>
    );
});

export default TransferVeryLowWageStep;
