import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import Header from './organisms/header';
import Footer from './organisms/footer';
import NavItem from './molecules/navbar/lib/NavItem';
import Sidenav from './molecules/sidenav';
import StatusMessage, { MessageType } from './molecules/status-message';
import PrintButton from './molecules/print-button';
import HeaderSlim from "./organisms/header-slim";
import RedirectPage from "./molecules/redirect-page";

export default function initializeLayout() {
    initializeHeader();
    initializeFooter();
    initializeSidenav();
    initializeErrors();
    initializePageLoading();
    initializePrintSection();
}

function initializeHeader() {
    loadWidget('.plf-header', header => {
        const navigationItems: NavItem[] = JSON.parse(header.dataset.menuEntries);

        const newDocumentsAction = header.dataset.newDocumentsAction;
        const newMessagesAction = header.dataset.newMessagesAction;

        if (!header.dataset.loggedIn) {
            const root: HTMLElement = document.querySelector(':root');
            const rootStyle = getComputedStyle(root);
            const scrollHeight = rootStyle.getPropertyValue('--scroll-top-height-logocentered');
            root.style.setProperty('--scroll-top-height', scrollHeight);
        }

        ReactDOM.render(<Header authenticated={header.dataset.loggedIn} isLeaving={header.dataset.userIsLeaving === '1'} navigationItems={navigationItems} newDocumentsAction={newDocumentsAction} newMessagesAction={newMessagesAction} />, header);
    });

    loadWidget('.plf-header-slim', header => {
        const loggedIn = Boolean(header.dataset.loggedIn);

        ReactDOM.render(<HeaderSlim loggedIn={loggedIn} />, header);
    });
}

function initializeFooter() {
    loadWidget('.plf-footer', footer => {
        const dataPrivacy = footer.dataset.dataPrivacy;
        const imprint = footer.dataset.imprint;
        const terms = footer.dataset.terms;

        ReactDOM.render(<Footer dataPrivacy={dataPrivacy} imprint={imprint} terms={terms} />, footer);
    });
}

function initializeSidenav() {
    loadWidget('.plf-sidenav', sidenav => {
        const parent = document.querySelector<HTMLElement>('.plf-sub-content');
        if (parent !== null) {
            ReactDOM.render(<Sidenav parent={parent} />, sidenav);
        }
    });
}

function initializePrintSection() {
    loadWidget('.plf-print-section', printSection => {
        ReactDOM.render(<PrintButton />, printSection);
    });
}

function initializeErrors() {
    loadWidget('.plf-status-message', errorWidget => {
        const description = errorWidget.dataset.description;
        const referenceId = errorWidget.dataset.referenceId;
        const title = errorWidget.dataset.title;
        const messageType = Object.prototype.hasOwnProperty.call(errorWidget.dataset, 'messageType') ?  errorWidget.dataset.messageType : MessageType.Error;

        ReactDOM.render(<StatusMessage messageType={messageType} description={description} referenceId={referenceId} title={title} />, errorWidget);
    });
}

function initializePageLoading() {
    loadWidget('.plf-redirect-page', redirectPageWidget => {
        const description = redirectPageWidget.dataset.description;
        const title = redirectPageWidget.dataset.title;
        const action = redirectPageWidget.dataset.action;

        ReactDOM.render(<RedirectPage description={description} action={action} title={title} />, redirectPageWidget);
    });
}

