import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import { StepProps } from '../../../lib/StepProps';
import FileContent from '../../../../../../../../design/1/js/lib/FileContent';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';

export interface ContinuationReasonsType {
    files: FileContent[];
    noPensionFundSwitch: boolean;
    terminationReason: string;
}

export const validationSchemaContinuationReasonsStep = Yup.object().shape({
    noPensionFundSwitch: Yup.boolean()
        .oneOf([true], () => window.sv_resource.get('form_errormsg_radio'))
        .required(() => window.sv_resource.get('form_errormsg_radio')),
    terminationReason: Yup.string().required(() => window.sv_resource.get('form_errormsg_radio')),
    files: Yup.array()
        .when('terminationReason', {
            is: terminationReason => terminationReason === 'terminationBecauseOfRestructuring' || terminationReason === 'terminationAfter58',
            then: Yup.array().of(
                Yup.object().shape({
                    file: Yup.string(),
                    fileName: Yup.string(),
                    fileType: Yup.string(),
                }),
            ).min(1, () => window.sv_resource.get('form_errormsg_upload'))
                .required(() => window.sv_resource.get('form_errormsg_upload')),
        }),
});

const ContinuationReasonsStep = (({
    disabled, errors, handleBack, handleFileChange, id, touched, onChange, onBlur, values,
}: StepProps<ContinuationReasonsType>) => {
    const baseClassName = 'w-continuationReasonsStep';

    const valuesAreSet = values.terminationReason !== '' && values.files.length > 0 && values.noPensionFundSwitch;

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_voluntary_continuation_reasons_title')}</h2>
            <div className="row gap-xxs">
                <Paragraph>{window.sv_resource.get('plf_onlineform_voluntary_continuation_reasons_description')}</Paragraph>
            </div>
            <div className="row gap-xxs">
                <div className={`${baseClassName}__radioWrapper`}>
                    <RadioButton
                        checked={values.terminationReason.toString() === 'terminationAfter58'}
                        disabled={disabled}
                        errors={errors.terminationReason}
                        id="terminationAfter58"
                        name="terminationReason"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.terminationReason}
                        label={window.sv_resource.get('plf_onlineform_voluntary_continuation_reason_termination_58')}
                    />
                    <RadioButton
                        checked={values.terminationReason.toString() === 'terminationBecauseOfRestructuring'}
                        disabled={disabled}
                        errors={errors.terminationReason}
                        id="terminationBecauseOfRestructuring"
                        name="terminationReason"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.terminationReason}
                        label={window.sv_resource.get('plf_onlineform_voluntary_continuation_reason_termination_55')}
                    />
                    <Checkbox
                        checked={values.noPensionFundSwitch}
                        errors={errors.noPensionFundSwitch}
                        disabled={values.terminationReason !== 'terminationBecauseOfRestructuring' && values.terminationReason !== 'terminationAfter58'}
                        touched={touched.noPensionFundSwitch}
                        id="noPensionFundSwitch"
                        name="noPensionFundSwitch"
                        onBlur={onBlur}
                        onChange={onChange}
                        label={window.sv_resource.get('plf_onlineform_voluntary_continuation_reason_no_transition')}
                    />
                </div>
                {
                    (values.terminationReason === 'terminationBecauseOfRestructuring' || values.terminationReason === 'terminationAfter58')
                    && (
                        <FileUploadField
                            accept="application/pdf, image/jpeg, image/png"
                            disabled={disabled}
                            label={window.sv_resource.get('plf_onlineform_voluntary_continuation_document')}
                            onChange={handleFileChange}
                            id="files"
                            placeholder=""
                            name="files"
                            errors={touched.files ? errors.files as string : ''}
                            onBlur={onBlur}
                            touched={!!touched.files}
                            value={values.files}
                        />
                    )
                }
            </div>
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton disabled={disabled || (Object.keys(errors).length > 0 || !valuesAreSet)} text={window.sv_resource.get('plf_onlineform_next_label')} variation={FormButtonVariation.Next} type="submit" />
            </div>
        </div>
    );
});

export default ContinuationReasonsStep;
