import React from 'react';

export default function formatErrorToastMessage(message: string, referenceId: string): JSX.Element {
    return (
        <>
            <div>
                {message}
                <br />
                <span className="small-text">
                    {`(${window.sv_resource.get('plf_error_reference_id')}: ${referenceId})`}
                </span>
            </div>
        </>
    );
}
