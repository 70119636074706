import * as Yup from "yup";

const getValidationSchema = () => Yup.object().shape({
    answer: Yup.string()
        .max(2000, window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '2000'))
        .required(window.sv_resource.get('form_errormsg_string')),
    files: Yup
        .array().of(
            Yup.object().shape({
                file: Yup.string(),
                fileName: Yup.string(),
                fileType: Yup.string(),
            }),
        ),
});

export default getValidationSchema;
